// SCSS variables
$bp-mobile: 49em;

// Updated mixins
@mixin bold-xl() {
    font-size: 1.375rem;
    font-weight: bold;
    line-height: 1.09;
    letter-spacing: .02682em;
}
@mixin bold-xxs() {
    font-size: .5rem;
    font-weight: bold;
    line-height: 2;
    color: var(--fill-mono-0-black);
}
@mixin regular-m() {
    font-size: 1rem;
    line-height: 1;
    letter-spacing: .03125em;
    color: var(--fill-mono-0-black);
    font-weight: normal;
}
@mixin regular-s() {
    font-size: .75rem;
    line-height: 1.23;
    font-weight: normal;
    color: var(--fill-mono-0-black);
}
@mixin regular-xs() {
    font-size: .625rem;
    line-height: 1.6;
    font-weight: normal;
    color: var(--fill-mono-0-black);
}
@mixin light-s() {
    font-size: .75rem;
    line-height: 1.23;
    font-weight: 300;
    color: var(--fill-mono-0-black);
}
@mixin button-text() {
    color: var(--fill-mono-white);
    font-size: .75rem;
    font-weight: bold;
    line-height: 1.23;
}
@mixin button-primary() {
    @include button-text();
    display: block;
    background: var(--fill-mono-0-black);
    border: 1px solid var(--grey-dark-10);
    text-align: center;
    user-select: none;
    padding: .75rem 1em;
    min-width: fit-content;
    width: 22.5rem;
    max-width: calc(100% - 6rem);
}
@mixin button-secondary() {
    @include button-text();
    display: block;
    padding: .75rem 1em;
    min-width: fit-content;
    width: 22.5rem;
    max-width: calc(100% - 6rem);
    color: var(--fill-mono-0-black);
    background: var(--fill-mono-white);
    text-align: center;
    user-select: none;
}

// Old mixins
// Update before using, and move to ^
// From design "Text styles" page
@mixin h1() {
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.08333;
}
@mixin h2() {
    font-size: 2rem;
    font-weight: normal;
    line-height: 1.125;
}
@mixin h3() {
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.16667;
}
@mixin h4() {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.2;
}
@mixin h5() {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
}

// In goabstract, the text style Paragraph === Body M
@mixin body-m() {
    font-size: 1rem;
    line-height: 1.25;
    font-weight: normal;
}
@mixin body-s() {
    font-size: .875rem;
    line-height: 1.28571;
    font-weight: normal;
}
@mixin body-xs() {
    font-size: .75rem;
    font-weight: normal;
    line-height: 1.33333;
}
@mixin body-xxs() {
    font-size: .625rem;
    font-weight: normal;
    line-height: 1.6;
}
@mixin label-m() {
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.28571;
}
@mixin label-s() {
    font-size: .75rem;
    font-weight: 600;
    line-height: 1.5;
}
@mixin price() {
    color: var(--primary-blue);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.16667;
}

// Other / Misc.
@mixin visually-hidden() {
    position: fixed;
    left: -10000px;
    margin-right: 0;
    transform: none;
    z-index: -1;
    opacity: 0;
}
@mixin tag() {
    @include label-m();
    display: inline-block;
    background: var(--brand-grass);
    border-radius: .75rem;
    padding: .2em 1em;
}
@mixin tooltip-top-hover() {
    bottom: 105%;
    opacity: 1;
    color: var(--dark-text-color);
}
@mixin add-button() {
    display: block;
    background: var(--brand-grass);
    border-radius: 50%;
    height: 5.5rem;
    width: 5.5rem;
}
