:root {
    // UI 2 (USE THESE)
    --pale-grey:              #ececee;

    --fill-red:               #f57170;
    --fill-alert-red:         #fa645f;

    --fill-mono-0-black:      #102542;
    --fill-mono-0-black-50:   rgba(16, 37, 66, .5);

    --fill-mono-white:        #ffffff;

    --grey-dark-40:           rgba(0, 0, 0, .4);
    --grey-dark-25:           rgba(0, 0, 0, .25);
    --grey-dark-10:           rgba(0, 0, 0, .1);
    --grey-dark-7:            rgba(0, 0, 0, .07);
    --grey-dark-3:            rgba(0, 0, 0, .03);

    --transparent:            transparent;
    --white:                  #fff;
    --light-gray:             #d7d7dc;

    --theme-dark-accent:      #262626;
    --dark-text-color:        #102542;

    --box-shadow-s:           0 2px 4px rgba(0, 0, 0, .5);
    --box-shadow-m:           0 4px 8px rgba(0, 0, 0, .5);
    --box-shadow-l:           0 6px 10px 0 rgba(0, 0, 0, .5);

    --border-gray:            1px solid rgba(0, 0, 0, .07);

    --border-radius-xxs:      2px;
    --border-radius-xs:       3px;
    --border-radius-s:        4px;
    --border-radius-m:        8px;

    --navigation-bar-width:   15rem;
    --navigation-bar-height:  3.5rem;

    --font-stack-primary:     'Lato',
                              'Lato Regular',
                              'Lato-Regular',
                              sans-serif;

    // UI 1 (DON'T USE THESE IN NEW CODE. REMOVE WHEN UNUSED)
    --background-hover:       rgba(223, 243, 245, .5);

    --theme-long-drink:       #f4f3ec;

    --action-green:           #47a571;
    --hover-gray:             #d9d7cf;
    --disabled-gray:          rgba(0, 0, 0, .07);

    --signal-red:             #ed5e46;
    --signal-green:           #8ce1c8;

    --light-text-color:       rgba(16, 37, 66, .5);
    --lighter-text-color:     rgba(16, 37, 77, .4);

    --fleet-blue-translucent: rgba(175, 235, 255, .7);

    --border-radius-l:        12px;
    --border-radius-xl:       20px;

    --box-shadow-primary:     0 4px 8px var(--grey-dark-25);

    --max-view-width:         100%;
    --inner-nav-bar-width:    20em;
    --top-padding:            12.5em;
}
