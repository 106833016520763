@charset "utf-8";

@import '_variables.scss';
@import '_mixins.scss';

// Globally applying styles
// And selectors with least amount of specificity (often just tag names)

::selection {background: var(--pale-grey)}
img::selection {background: var(--fleet-blue-translucent)}

::-webkit-scrollbar {
    width: .5em;
    height: .5em;
    background: var(--grey-dark-10);
    border-radius: 1em;

    &-thumb {
        background: var(--grey-dark-25);
        border-radius: 1em;
    }
}

.thick-scrollbar {
    &::-webkit-scrollbar {
        width: 1em;
        height: 1em;
    }

    &.left-not-rounded {
        &::-webkit-scrollbar {border-radius: 0 .5em .5em 0}
    }
}

*,
*::before,
*::after {box-sizing: border-box}

:root {
    width: 100%;
    height: 100%;
    font-size: 100%;
    line-height: 1.25;
    background: var(--pale-grey);
    scroll-behavior: smooth;
    color: var(--dark-text-color);
    font-family: var(--font-stack-primary);
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    background: inherit;
}

h1 {
    @include h1();
    margin: 0;
}

h2 {
    @include h2();
    margin: 0;
}

h3 {
    @include h3();
    margin: 0;
}

h4 {
    @include h4();
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    transition: color .2s;

    &:hover {
        text-decoration: none;
        color: var(--light-text-color);
    }
}

label {
    display: inline;
    margin: 0;
}

img {
    max-width: 100%;
    max-height: 100%;
}

address {margin: 0}
table {border-spacing: 0}

button,
[type='button'],
[type='submit'],
[type='reset'] {
    @include button-text();
    appearance: none;
    background: none;
    min-width: fit-content;
    padding: 0;
    border: 0;
    line-height: inherit;
    color: inherit;
    border-radius: 99em;
    font-family: inherit;
    font-size: 1em;
    margin: 0 auto;
    transition: color .2s,
                filter .2s,
                opacity .2s,
                background .2s;

    &:not(:disabled) {cursor: pointer}

    &:not(:disabled):hover {
        text-decoration: none;
        filter: brightness(1.1);
        color: var(--light-text-color);
    }

    &:not(:disabled):focus {outline: none;}
    &:not(:disabled):active {filter: brightness(.9)}

    &[disabled],
    &.disabled,
    &:disabled {background: var(--fill-mono-0-black-50)}
}

.button {
    @include button-text();
    display: inline-block;
    background: none;
    border: 0;
    padding: 0;
    border-radius: 99em;
    text-align: center;

    &--primary {@include button-primary()}
    &--secondary {@include button-secondary()}

    &--link {
        @include button-text();
        background: none;
        border: 0;
        text-decoration: underline;
        transition: color .2s;
    }
}

[type='text'],
[type='email'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='url'] {
    @include body-m();
    appearance: none;
    font-family: inherit;
    line-height: initial; // Prevents cutoff text
    color: inherit;
    border-radius: var(--border-radius-xxs);
    background: var(--white);
    width: 100%;
    border: 1px solid transparent;
    padding: calc(.75em - 1px) 1em;
    transition: border .2s,
                box-shadow .2s;

    &:focus {
        box-shadow: var(--box-shadow-primary);
        outline: none;
    }

    &::placeholder {color: var(--light-text-color)}
}

select {
    @include body-m();
    font-family: inherit;
    font-size: 1em;
    color: inherit;
    border-radius: var(--border-radius-s);
    background: var(--white);
    width: 100%;
    border: 1px solid transparent;
    padding: calc(.75em - 1px) 3em calc(.75em - 1px) 1em;
    transition: border .2s,
                box-shadow .2s;

    &:focus {
        box-shadow: var(--box-shadow-primary);
        outline: none;
    }
}

#root {
    height: 100%;
    width: 100%;
    max-width: var(--max-view-width);
    margin: 0 auto;
    position: relative;
}

.tag {@include tag()}

[data-tooltip] {
    position: relative;

    &::before {
        @include body-xs();
        content: attr(data-tooltip);
        opacity: 0;
        position: absolute;
        top: 95%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        padding: .25rem .5rem;
        box-shadow: var(--box-shadow-s);
        border-radius: var(--border-radius-xs);
        background: var(--white);
        color: transparent;
        pointer-events: none;
        text-align: center;
        transition: all .2s,
                    color .2s;
    }

    &:hover {
        &::before {
            top: 105%;
            opacity: 1;
            color: var(--dark-text-color);
        }
    }

    &.tooltip-right {
        &::before {
            top: 50%;
            right: auto;
            bottom: auto;
            left: 95%;
            transform: translateY(-50%);
        }

        &:hover {
            &::before {
                left: 105%;
                bottom: auto;
            }
        }
    }
}

@media screen and (max-width: $bp-mobile) {

    .hide-mobile {display: none !important;}

}
