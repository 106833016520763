@import 'styles/_mixins.scss';

.core-wrapper {
    display: flex;
    flex-flow: row;
    height: 100vh;

    .core-content {
        flex: 1 1 auto;
        overflow: auto;
        position: relative;
    }
}

@media screen and (max-width: $bp-mobile) {
    .core-wrapper {
        flex-flow: column-reverse;
    }

}
